.App {
  text-align: center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.home-button,
.about-button {
  font-family: "Zebraesq";
  text-decoration: none;
  background-color: #dcdada;
  padding: 10px 10px;
  border-radius: 35px;
  box-shadow: 0 15px 15px rgba(0, 0, 0, 0.3);
  overflow: hidden;
  color: black;
  flex-direction: column;
}

.button-wrapper > * {
  margin-right: 70px;
}

.navigate {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: rgb(151, 187, 130);
  padding: 10px 20px;
  flex-wrap: wrap;
}

.navigate a {
  text-decoration: none;
  color: black;
  font-size: 1rem;
  transition: color 0.3s ease;
}

.navigate a:hover {
  color: green;
}

@media (max-width: 768px) {
  .navigate {
    flex-direction: column;
    align-items: flex-start;
  }

  .navigate a {
    margin-left: 0;
    margin-top: 10px;
  }

  .home-button,
  .about-button {
    margin-left: auto;
    margin-right: 20px;
  }
}

.intro {
  font-family: "Zebraesq";
  margin: 50px;
}

.para {
  font-family: "Zebraesq";
  margin: 50px;
  font-size: 20px;
}

.description {
  font-family: "Zebraesq";
  margin: 50px;
}

.front-header {
  font-family: "Zebraesq";
  margin: 50px;
}

.front-info {
  margin: 50px;
}

.sentient-header {
  font-family: "Zebraesq";
  margin: 50px;
}

.sentient-info {
  margin: 50px;
}

.fashion-header {
  font-family: "Zebraesq";
  margin: 50px;
}

.fashion-info {
  margin: 50px;
}

.welcome {
  font-family: "Zebraesq";
  margin: 50px;
}

.descript {
  font-family: "Zebraesq";
  margin: 50px;
}
 
.footer {
  font-weight: bold;
  background-color: rgb(0, 0, 0);
  color: white;
  padding: 0.3cm;
  margin-top: auto;
  text-align: center;
  font-family: "Zebraesq";
}

.footer-info {
  font-family: "Zebraesq";
}

.social-media {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.social-media a {
  margin: 10px; 
}

.social-media a img {
  width: 50px; 
  height: 50px; 
}

@media (max-width: 768px) {
  .social-media {
    flex-direction: row; 
  }
  .social-media a {
    margin: 5px; 
  }
}

.links {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  gap: 16px;
  padding: 16px;
}

.links img {
  max-width: 100%;
  height: auto;
  box-shadow: 0 15px 15px rgba(0, 0, 0, 0.5);
  border-radius: 35px;
}


@media (min-width: 600px) {
  .links {
    flex-wrap: nowrap; 
  }
}

@media (max-width: 599px) {
  .links {
    flex-direction: column;
    align-items: center;
  }
}

.site-logo {
  width: 250px; 
  height: 250px; 
}

.App-header {
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: calc(10px + 2vmin);
  color: rgb(0, 0, 0);
  margin-bottom: 30px;
}

.App-link {
  color: #000000;
}

:root {
  --flourescent-blue: hsl(204, 81%, 51%);
  --heliotrope-gray: hsl(0, 0%, 0%);
  --russian-violet: hsl(252, 80%, 16%);
  --dark-violet: hsl(252, 100%, 12%);
  --space-cadet: hsl(247, 43%, 20%);
  --blue-ryb: hsl(226, 69%, 50%);

  --gradient: linear-gradient(to right, var(--flourescent-blue), var(--blue-ryb));

  --fs-1: 25px;
  --fs-2: 18px;
  --fs-3: 17px;
  --fs-4: 15px;

  --fw-500: 500;
  --fw-700: 700;

  --transition: 0.25s ease-in-out;

  --section-padding: 30px;
}

a { text-decoration: none; }

img,
input,
ion-icon
 { display: block; }

button {
  font: inherit;
  background: none;
  border: none;
  cursor: pointer;
}

input {
  font: inherit;
  border: none;
  width: 100%;
}

:focus { outline-offset: 4px; }

::-webkit-scrollbar { width: 10px; }

::-webkit-scrollbar-track {
  background: green;
  border-left: 1px solid var(--space-cadet);
}

::-webkit-scrollbar-thumb { background: hsla(0, 0%, 100%, 0.8); }

::-webkit-scrollbar-thumb:hover { background: hsla(0, 0%, 100%, 0.6); }

.btn {
  color: var(--white);
  font-size: var(--fs-4);
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  padding: 9px 10px;
  border-radius: 4px;
}

.btn-primary {
  background: var(--gradient);
  background-size: 500%;
  background-position: right;
  padding: 15px 25px;
  gap: 8px;
  transition: background var(--transition);
}

.h3 {
  font-size: var(--fs-2);
  line-height: 1.5;
  color: black;
}

.hero { 
  padding: 140px 0 var(--section-padding); 
}

.hero-title {
  width: 100%;
  margin-bottom: 20px;
}

.hero-text {
  color: var(--white);
  font-size: var(--fs-4);
  line-height: 1.8;
  margin-bottom: 20px;
}

.hero .btn-primary { margin-bottom: 25px; }

.btn-title {
  background: var(--gradient);
  width: max-content;
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
  font-size: var(--fs-4);
  font-weight: var(--fw-700);
  margin-bottom: 25px;
}

.hero .btn-link {
  color: var(--white);
  font-size: var(--fs-4);
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: max-content;
  background: var(--gradient);
  padding-bottom: 5px;
  background-size: 0 2px;
  background-repeat: no-repeat;
  background-position: 0 97%;
  border-radius: 4px;
  transition: background var(--transition);
}

.hero .btn-link:is(:hover, :focus) { 
  background-size: 100% 2px; 
}

.hero .btn-link { 
  margin-bottom: 15px; 
}

.podcast { 
  padding: var(--section-padding) 0; 
}

.podcast-list {
  display: grid;
  gap: 30px;
}

.podcast-card { 
  border-radius: 4px; 
}

.card-banner {
  position: relative;
  border-radius: 8px;
  overflow: hidden;
  margin-bottom: 20px;
  z-index: 1;
}

.card-banner::before {
  content: "";
  position: absolute;
  inset: 0;
}

.podcast-card:is(:hover, :focus) .card-banner::before {
  background: hsla(0, 0%, 100%, 0.1);
}

.card-banner img { 
  width: 100%; 
}

.card-banner-icon {
  position: absolute;
  bottom: 20px;
  right: 20px;
  width: 50px;
  height: 50px;
  display: grid;
  place-items: center;
  color: var(--white);
  border: 2px solid var(--white);
  border-radius: 50%;
  font-size: 20px;
  transition: var(--transition);
}

.podcast-card:is(:hover, :focus) .card-banner-icon {
  background: var(--white);
  color: var(--blue-ryb);
}

.hero-btn-group a {
  text-decoration: none;
  color: #ffffff;
}

.card-meta {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  color: var(--heliotrope-gray);
  font-size: var(--fs-4);
  margin-left: 35px;
  margin-bottom: 15px;
}

.card-meta::before {
  content: "";
  position: absolute;
  top: 50%;
  left: -35px;
  transform: translateY(-2px);
  width: 25px;
  height: 2px;
  background: var(--heliotrope-gray);
}

.podcast-card .card-title {
  display: inline;
  padding: 3px 0;
  background: var(--gradient);
  background-position: 0 95%;
  background-repeat: no-repeat;
  background-size: 0 2px;
  transition: var(--transition);
}

.podcast-card:is(:hover, :focus) .card-title { 
  background-size: 100% 2px; 
}

.card-title { 
  color: black;
}

.newsletter { 
  padding: var(--section-padding) 0; 
}

.newsletter-card {
  background-image:url("/public/new-black.png");
  background-size: cover;
  padding: 60px 20px;
  border-radius: 12px;
  color: #ffffff;
}

.newsletter-card .card-title {
  font-size: var(--fs-1);
  line-height: 1.3;
  margin-bottom: 10px;
  color: #ffffff;
}

.newsletter-card .card-text {
  font-size: var(--fs-4);
  line-height: 1.5;
  margin-bottom: 20px;
}

.newsletter-card .input-field {
  padding: 18px 15px;
  border-radius: 4px;
  background: hsla(0, 0%, 100%, 0.3);
  color: var(--white);
  font-size: var(--fs-4);
  border: 1px solid var(--white);
  margin-bottom: 20px;
}

.newsletter-card .input-field:focus { 
  outline: none; 
}

.newsletter-card .input-field::placeholder {
  color: inherit;
  transition: var(--transition);
}

.newsletter-card .input-field:focus::placeholder { 
  opacity: 0; 
}

.newsletter-card .btn-primary {
  font-size: var(--fs-3);
  width: 100%;
  padding-block: 18px;
}

.newsletter-card .btn-primary:disabled {
  cursor: not-allowed;
  background-position: right;
}

/* Styles for larger screens */
.hero-banner img {
  border-radius: 25%;
  max-width: 650px;
  padding: 0;
  display: block;
  margin: 0 auto; /* Center the image by default */
}

/* Media query for smaller screens */
@media (max-width: 600px) {
  .hero-banner img {
    margin: 0; /* Remove auto centering */
    float: left; /* Move image to the left */
    width: 100%;
  }

  .hero-banner img {
    padding: 0; /* Remove padding from the container */
    margin: 0; /* Remove margin from the container */
    display: flex; /* Use flexbox for alignment */
    justify-content: flex-start; /* Align items to the start (left) */
  }
}

@media (min-width: 768px) {
  :root {
    --fs-2: 18px;
    --fs-4: 18px;
  }

  .container { 
    max-width: 680px; 
  }

  .hero-title {
    width: 90%; 
  }

  .hero :is(.btn-primary, .btn-title, .btn-link) {
    --fs-4: 16px;
    margin-bottom: 0;
  }

  .hero-btn-group {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 25px;
  }

  .hero-btn-group a {
    text-decoration: none;
    color: #ffffff;
  }

  .btn-link-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 25px;
  }

  .podcast-list { 
    grid-template-columns: 1fr 1fr; 
  }

  .newsletter-card { 
    padding: 50px; 
  }

  .card-form { 
    position: relative; 
  }

  .newsletter-card .btn-primary {
    position: absolute;
    top: 4px;
    right: 4px;
    bottom: 4px;
    width: 140px;
  }

  .newsletter-card .input-field { 
    padding-right: 155px; 
  }
}

@media (min-width: 1024px) {
  .container {
    max-width: 980px; 
  }
  
  .navbar::before,
  .overlay { 
    display: none; 
  }

  .newsletter-card {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 50px;
  }

  .newsletter-card .card-content,
  .card-form { 
    width: 50%; 
  }

  .newsletter-card .card-text,
  .newsletter-card .input-field { 
    margin-bottom: 0; 
  }

  .hero-btn-group {
    text-decoration: none;
  }
}

@media (min-width: 1200px) {
  :root {
    --section-padding: 60px;
  }

  .container { 
    max-width: 1200px; 
  }

  .hero {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    gap: 30px;
    padding-top: 160px;
  }

  .hero-content {
    width: 95%;
    margin-inline: 0;
  }

  .hero-title {
    width: 100%;
    margin-bottom: 30px;
  }

  .hero-text { 
    margin-bottom: 25px; 
  }

  .hero-btn-group {
    flex-wrap: wrap-reverse;
    gap: 35px;
    text-decoration: none;
  }

  .hero :is(.btn-primary, .btn-title, .btn-link) { 
    --fs-4: 17px; 
  }

  .hero-banner {
    display: block;
    border-radius: 100px;
  }

  .podcast-list { 
    grid-template-columns: repeat(4, 1fr); 
  }
}