body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: "FFF_Tusj";
  src: local("FFF_Tusj") url("./fonts/FFF_Tusj.ttf") format("truetype")
}

@font-face {
  font-family: "Villa";
  src: local("Villa") url("./fonts/Villa.ttf") format("truetype")
}

@font-face {
  font-family: "Zebraesq";
  src: local("Zebraesq") url("./fonts/Zebraesq.ttf") format("truetype")
}
